<template>
  <div class="root">
    <div class="header">
      <h3 class="head_h1">参加企业调查列表</h3>
      <span class="head_span">共{{ amount }}家</span>

      <div>
        <el-button @click="addCom()">
          评测
        </el-button>
        <el-button  @click="exit()">
          退出
        </el-button>
      </div>




    </div>
    <div class="search">
      <el-input class="seach_input" v-model="searchInput" placeholder="请输入查找的内容"/>
      <el-button type="success" @click="list">查找</el-button>
    </div>

    <div class="table">
      <el-table class="el-table" :data="form" border>
        <el-table-column fixed prop="co_name" label="企业名字" width="260"/>
        <el-table-column prop="co_area" label="所属地区" width="150"/>
        <el-table-column prop="co_sort" label="所属分类" width="150"/>
        <el-table-column prop="gross_scorce" sortable label="总分" width="120"/>
        <el-table-column prop="policy_scores" sortable label="国家政策分" width="120"/>
        <el-table-column prop="industry_scores"  sortable label="产业导向分" width="120"/>
        <el-table-column prop="business_scores" sortable label="衍生业务分" width="120"/>
        <el-table-column prop="edit_time" sortable  label="更新时间" width="170"/>
        <el-table-column fixed="right" label="功能操作" width="160">
          <template #default="scope">
            <el-button type="danger" size="small" @click="handleClick(scope.row)"
            >删
            </el-button
            >
            <el-button type="primary" size="small" @click="handleUpadate(scope.row)">改</el-button>
            <el-button type="primary" size="small"   @click="handleToView(scope.row)">查</el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>


    <!--page-->
    <div class="page">
      <div class="demo-pagination-block">
        <el-pagination
            @size-change="handlePageSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5,10,15]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>

import request from "@/network/request";

export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      form: {},
      currentPage: 1,
      pageSize: 10,
      total: 10,
      searchInput:"",
      amount:""
    };
  },
  methods: {
    formatDate(oldDate) {
      // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
      function add0(num) {
        return num < 10 ? '0' + num : num
      } // 个位数的值在前面补0
      const date = new Date(oldDate)
      const Y = date.getFullYear()
      const M = date.getMonth() + 1
      const D = date.getDate()
      const h = date.getHours()
      const m = date.getMinutes()
      const s = date.getSeconds()

      const dateString = Y + '-' + add0(M) + '-' + add0(D) + '  ' + add0(h) + ':' + add0(m) + ':' + add0(s)
      return dateString

      // 方式2 转换为'yyyy/MM/dd HH:mm:ss'
      // return new Date(oldDate).toLocaleString()
    },
    handleCurrentChange(pageNum) {
      //处理每页显示多少条记录变化
      this.currentPage = pageNum;
      this.list();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.list();
    },
    list(){
      request.get("http://106.15.57.195:10000/queryComNameBySearch",  {
        params: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          search:this.searchInput
        }
      }).then((res) => {

        // console.log("res",res);
        this.form = res.data.data.records;
        // console.log("res.data.total",res.data.data.total)
        this.total = res.data.data.total;
        for (let re of this.form ) {
          re.edit_time = this.formatDate(re.edit_time);
          // console.log("re.edit_time", re.edit_time);
        }

        // console.log("form", this.form)
      })
    },
    handleClick(info){
      // console.log("正在删除",info.co_index)
      request.delete("http://106.15.57.195:10000/deleteCom/"+info.co_index).then(res=>{
        // console.log("响应结果",res)
        if (res.data.code == "200"){

          this.$message({
            //弹出更新成功的消息框
            type: "success",
            message: "删除成功",
          })
          this.list();
          this.getAmout();
        }else {
          this.$message({
            //弹出更新成功的消息框
            type: "error",
            message: "删除失败",
          })
          this.list();
        }


      })

    },
    handleUpadate(info){
    // 处理更新

      this.$message({
        //弹出更新成功的消息框
        type: "success",
        message: "准备更改，请稍等",
      })
      // console.log("info=>",info.co_index)
      this.$router.push({
        name:"UpdateTopic",
        params:{
          index:info.co_index
        }

      })
    },
    handleToView(info){
      this.$message({
        //弹出更新成功的消息框
        type: "success",
        message: "查询中",
      })
      // console.log("info=>",info.co_index)
      this.$router.push({
        name:"ViewTopic",
        params:{
          index:info.co_index
        }

      })

    },
    exit(){
      //移除本地token
      localStorage.removeItem('token');
      this.$message({
        //弹出更新成功的消息框
        type: "success",
        message: "退出成功",
      })
      window.location.reload();
    },
    addCom(){
      this.$message({
        //弹出更新成功的消息框
        type: "success",
        message: "稍等 正在创建评测",
      })
      this.$router.push({
        name:"Topic"
      })



    },
    getAmout(){
      request.get("http://106.15.57.195:10000/amount").then(res=>{
        // console.log("总共多少页",res.data);
        this.amount = res.data;
      })

    }


  },
  created() {
    this.list();
    this.getAmout();
  }
};
</script>

<style scoped>

.root {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.header {
  /*height: 50%;*/

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
  line-height: 30px;
}

.header h3 {
  font-weight: bold;
}

.header el-button {
  margin-top: -1%;
}

.search {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 2%;
}

.header span {
  font-weight: bold;
}

.seach_input {
  width: 40%;
}


.table {
  background-color: salmon;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 10px;
  width: 96%;
}


.page {

  width: 95%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
}


</style>
  