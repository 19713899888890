<template>
  <div id="app">

    <section>

        <router-view/>


    </section>

  </div>
</template>

<script>


export default {
  name: "Layout",
  components: {},

};
</script>
<style>
#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

section {
  flex: 1;
}




</style>
