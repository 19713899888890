<template>
  <div id="box">
    <header>
      <el-page-header class="el-page-header" @back="handleToPageMainView()">
        <template #content>
          <span class="text-large font-600 mr-3"> 企业调查完成 </span>
        </template>
      </el-page-header>

    </header>


    <div id="chart">

      <div id="GrossScorce"></div>
      <div id="two"></div>
      <div id="three"></div>
      <div id="four"></div>
      <div id="info">
        <h1>企业名称：{{ info }}</h1>
        <h1>企业得分：{{ score }}</h1>
        <h1>企业评类：{{ level }}</h1>
        <span>A类——优质企业：≥55分</span>
        <span>B类——高潜企业：≥50分</span>
        <span>C类——普通企业：≥45分</span>
        <span>D类——入围企业：≥40分</span>
      </div>
      <div id="footer">
        <el-button .class="el-button" @click="handleToPageMainView()">返回列表</el-button>
        <el-button .class="el-button" type="primary" @click="handlerRouteTopic()">继续增加</el-button>
      </div>

    </div>


  </div>


</template>

<script>
//引用echats
import * as echarts from "echarts";
//使用Mounted生命周期
import request from "@/network/request";

export default {

  data() {
    return {
      data: {},
      scaleData: [], // 饼状图数据
      scaleLegend: [], // 饼状图标注
      info: "",
      score: "",
      level: ""
    }
  },
  setup() {
  },
  mounted() {
    this.getPieEcharts();

  },
  methods: {
    resizeWin() {
      // console.log(document.documentElement.clientWidth);
      //当前window 宽
      this.centerWidth = document.documentElement.scrollWidth;

      //最小宽度 1440
      var boxH = 92,
          boxW = 92;
      if (this.centerWidth > 1440) {
        boxH = boxW = Math.ceil((this.centerWidth / 1920) * 92);
        // console.log("H", boxH);
      } else {
        boxH = boxW = Math.ceil((1440 / 1920) * 92);
      }
      // var item = document.getElementsByClassName("street-item");
      // console.log(this.style01.width);

      this.style01.width = boxW + "px";
      this.style01.height = boxH + "px";
      this.style01.lineHeight = boxH + "px";
      this.style01.borderRadius = boxW / 2 + "px";
    },
    handleToPageMainView() {


      this.$router.push({
        name: "MainView"
      })
    },
    handlerRouteTopic() {

      this.$message({
        //弹出更新成功的消息框
        type: "success",
        message: "准备新增,请稍等",
      })
      this.$router.push({
        name: 'Topic',
      })
    },
    getPath() {
      console.log(this.$route.path);
      return this.$route.path;
    },
    getPieEcharts() {

      let scaleData = []
      let context = ""
      // 移除再创建 防止缓存
      document.getElementById('GrossScorce').removeAttribute('_echarts_instance_');
      document.getElementById('two').removeAttribute('_echarts_instance_');
      document.getElementById('three').removeAttribute('_echarts_instance_');
      document.getElementById('four').removeAttribute('_echarts_instance_');

      const GrossScorce = echarts.init(document.getElementById('GrossScorce'))// 图标初始化
      const two = echarts.init(document.getElementById('two'))// 图标初始化
      const three = echarts.init(document.getElementById('three'))// 图标初始化
      const four = echarts.init(document.getElementById('four'))// 图标初始化


      // console.log("this.getPath().substring(11) =>"+this.getPath().substring(12))
      request.get("http://106.15.57.195:10000/queryViewDate/" + this.getPath().substring(11)).then(res => {

        this.info = res.data.data[0][1].context;
        //拿到第一组总分信息
        scaleData = res.data.data[0];

        const dataCount = [];
        dataCount.push(res.data.data[0][1]);
        dataCount.push(res.data.data[1][0]);
        dataCount.push(res.data.data[2][0]);
        dataCount.push(res.data.data[3][0]);

        for (const item of dataCount) {
          console.log("item", item.name)

        }


        console.log("结果打印", dataCount)


        this.score = res.data.data[0][0].value;
        if (Number(res.data.data[0][0].value) >= 55) {
          this.level = "A";
        } else if (Number(res.data.data[0][0].value) >= 50) {

          this.level = "B";
        } else if (Number(res.data.data[0][0].value) >= 45) {

          this.level = "C";
        } else if (Number(res.data.data[0][0].value) >= 40) {

          this.level = "D";
        } else {
          this.level = "分数太低了,无法评类";
        }


        context = res.data.data[0][0].context;



        GrossScorce.setOption( {
          title: {
            text: res.data.data[0][0].context,
            // subtext: 'Fake Data',
            left: 'center'
          },

          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: false, readOnly: false },
              restore: { show: false },
              saveAsImage: { show: false }
            }
          },
          series: [
            {
              name: 'Nightingale Chart',
              type: 'pie',
              radius: [10, 55],
              center: ['50%', '65%'],
              avoidLabelOverlap: false,
              roseType: 'area',
              itemStyle: {
                borderRadius: 8
              },
              data: dataCount
            }
          ]
        } )

        two.setOption({
          title: {
            text: res.data.data[1][0].context,
            // subtext: 'Fake Data',
            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: 'bottom',
            left: 'center'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '40',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: res.data.data[1]
            }
          ]
        })
        three.setOption({
          title: {
            text: res.data.data[2][0].context,
            // subtext: 'Fake Data',
            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: 'bottom',
            left: 'center'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '40',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true
              },
              data: res.data.data[2]
            }
          ]
        })
        four.setOption({
          title: {
            text: res.data.data[3][0].context,
            // subtext: 'Fake Data',
            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: 'bottom',
            left: 'center'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '40',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: res.data.data[3]
            }
          ]
        })

      })
      // 随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        GrossScorce.resize()
        two.resize()
        three.resize()
        four.resize()
      })
    },


  },


};

</script>

<style scoped>

#box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /*background-color: #409eff;*/


}


header {
  width: 100%;
  height: 55px;
  background-color: #549fc9;
  position: fixed;
  z-index: 100;

}

#chart {
  /*margin-top: 20px;*/
  width: 100%;
  height: 90%;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 8%;
}


#GrossScorce {
  z-index: 20;
  width: 300px;
  height: 26%;
  /*padding-bottom: 20px;*/
  /*background-color: #ffc000;*/
  /*border: 1px solid black;*/
  /*margin-left: 5%;*/
  /*align-items:flex-start;*/

}

#two {
  width: 300px;
  height: 26%;
  /*border: 1px solid black;*/
  align-items: center;
  /*margin-left: 5%;*/

}

#three {
  width: 300px;
  height: 26%;
  /*border: 1px solid black;*/
  align-items: center;
  /*margin-left: 5%;*/

}


#four {
  width: 300px;
  height: 26%;
  /*border: 1px solid black;*/
  align-items: center;
  /*margin-left: 5%;*/
}

#test {
  width: 300px;
  height: 26%;
  border: 1px solid black;
  align-items: center;
  /*margin-left: 5%;*/
}

#info {
  width: 300px;
  height: 26%;
  /*border: 1px solid black;*/
  align-items: center;
  /*margin-left: 5%;*/
  display: flex;
  flex-direction: column;
  margin-right: 5%;

  /*flex-wrap: nowrap;*/
  /*background-color: #409eff;*/


}

#info h1 {
  font-weight: bolder;
  margin-top: 10px;
}

#info span {
  font-weight: bold;
  margin-top: 10px;
}


/*#footer{*/
/*  width: 300px;*/
/*  height: 26%;*/
/*  !*border: 1px solid black;*!*/
/*  align-items: center;*/
/*  margin-left: 5%;*/
/*}*/

#footer {
  width: 100%;
  height: 4%;
  /*text-align: center;*/
  /*background-color: sandybrown;*/
  /*position: relative;*/
  /*align-items: center;*/
  display: flex;
  justify-content: center;
  margin-right: 10%;
}

.text-large {
  color: white;
}

.el-page-header {
  color: white;
}
</style>
