<template>
  <div id="root">
    <div id="header">
      <div>
        <el-page-header class="el-page-header" @back="HandleCancel" :icon="ArrowLeft">
          <template #content>
            <span class="text-large font-600 mr-3"> 企业调查新增 </span>
          </template>
          <template #extra>
            <div class="flex items-center">
              <el-button class="top-button" @click="dialogVisible = true" >提示</el-button>
<!--                            <el-button class="top-button" @click="getGroup">提示</el-button>-->
            </div>
          </template>
        </el-page-header>
      </div>
    </div>

    <div class="content">
      <div id="topics">
        <div>
          <!--表单设置-->
          <el-form
              ref="form"
              :model="this.form.data.dataList"
              label-width="top"
              :label-position="labelPosition"
              :size="size"
          >


            <el-form-item
                v-for="(items,index) in this.form.data.dataList"
                :key="index"
                :class="{'flag_blue': Number(items.topic.topic_flag) === 2 ,
                            'flag_yellow': Number(items.topic.topic_flag) === 1}"
                :label="items.topic.topic_content">
<!--              {{this.form.data.dataList[index].answer.single_entry}}-->

              <!--          el-select  使用说明
                            v-model= 该值绑定选中选项
              -->
              <!--              信息={{Number(items.topic.topic_answers_type)===5}}-->

              <!--单选 1  -->
              <el-select

                  v-if="Number(items.topic.topic_answers_type) == 1"
                  class="el-select"
                  v-model="items.answer.topic_single_selection.userSelection"
                  :placeholder="items.topic.topic_placeholder">
                <!-- v-for是值 -->
                <el-option :class="windowWidthInfo"
                           v-for=" (opt,index) in
                          items.answer.topic_single_selection.single_selection.options"

                           :label="opt.option"
                           :value="opt.value"/>


              </el-select>

              <!--              {{ Number(items.topic.topic_answers_type) == 2?items.answer.multiple_choice.multiple_choice.choices :false}}-->

              <!--多选 2-->
              <el-checkbox class="el-checkbox" v-if="Number(items.topic.topic_answers_type) == 2"
                           v-for="(choices,index) in  items.answer.multiple_choice.multiple_choice.choices"
                           v-model="choices.status"
                           :label="choices.option"/>


              <!--单条输入 4  -->
              <el-input
                        :placeholder="Number(items.topic.topic_answers_type)
                         == 4?items.topic.topic_placeholder:false"
                        v-if="Number(items.topic.topic_answers_type) == 4"

                        v-model.trim="items.answer.single_entry"/>

              <!--多条输入-->
              <el-input
                  v-for="(i,index) in  items.answer.multiple_inputs.inputvalues.item"
                  oninput="if(value.length>6)value=value.slice(0,12)"
                  :placeholder="Number(items.topic.topic_answers_type) == 5?i.placeholder:false"
                  v-if="Number(items.topic.topic_answers_type) == 5"

                  onkeyup = "value=value.replace( /^\D*(\d*(?:\.\d{0,4})?).*$/g,'$1')"

                  v-model.trim="i.values">
                <template #prepend>{{ i.name }}</template>
                <template #append>{{ i.unit }}</template>
              </el-input>


            </el-form-item>

          </el-form>
        </div>
        <div id="submit">
          <el-button @click="HandleCancel">取消</el-button>

          <el-button
              v-loading.fullscreen.lock="fullscreenLoading"
              type="primary" @click="openFullScreen1()">
            提交
          </el-button>
        </div>
      </div>


    </div>

    <div>
      <el-dialog
          v-model="dialogVisible"
          title="试题说明"
          width="80%"
          :before-close="handleClose"
      >
        <div id="PopInfo">
          <h2>注意:如不填写企业名称，将不会保存试题</h2>
          <h2>注意:如该题没有输入答案（选择,多选可以不选择）将以0分保存</h2>
          <h2>注意:多条输入信息，知识产权,可以只输入有的内容,没有则不需要输入</h2>
          <h2><span @click="handleToBlockOne()" class="span-whilt">无色</span> 第一阶段调研的问题</h2>
          <h2><span class="span-yellow">黄色</span> 第二阶段调研的问题</h2>
          <h2><span class="span-blue">蓝色</span> 第三阶段调研的问题</h2>
          <h2>共计 {{ this.form.data.dataList.length }} 道题目</h2>
        </div>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">我知道了</el-button>
      </span>
        </template>
      </el-dialog>
    </div>


  </div>
</template>

<script>
import request from "@/network/request";

export default {


  name: "Topic",
  data() {
    return {
      form: {
        data: {
          dataList: []
        }
      },
      sendForm: {},
      size: "large", //表单字体 表单控件
      labelPosition: "top", //表单方向 表单控件
      status: 1,//状态信息 可以删除测试之后
      fullscreenLoading: false, //按钮信息,
      windowWidthInfo: "", //当前窗口信息
      dialogVisible: false,
      rules:{
        name: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
      }

    };
  },
  methods: {
    openFullScreen1() {
      this.fullscreenLoading = true


      setTimeout(() => {
        this.fullscreenLoading = false
        this.submitForm();
      }, 0)


    },
    submitForm() {
      // this.openFullScreen1();

      this.getGroup();

      request.post("http://106.15.57.195:10000/testA", this.form).then((result) => {
        console.log("result==>", result);
        if (result.data.data.code == "200") {
          this.$message({
            //弹出更新成功的消息框
            type: "success",
            message: "正在提交问卷,请稍等",
          })

          this.$router.replace({path:"/ViewResult/"+this.form.company.co_index})
         return  result;

        }
      })
    },
    HandleCancel() {
      this.$confirm(
          `确认保存吗`, // 第一个参数为弹窗消息内容
          "提示", // 第二个参数为弹窗左上角标题title
          // 第三个参数为弹窗项的options，应该为object对象
          {
            confirmButtonText: "保存", // 确认按钮的文本，可省略，默认为确定
            cancelButtonText: "不保存", // 取消按钮的文本，可省略，默认为取消
            type: "warning", // 弹窗的消息类型，比如为warning时弹窗左边图标为'!'感叹号，为success时图标为'√'的勾。
          }
      )
          // then中填写点击确认按钮后执行的事件，例如执行删除该条数据的delect请求
          .then(() => {
            this.$message({
              //弹出更新成功的消息框
              type: "success",
              message: "准备保存",
            })
            this.submitForm();

          })
          // catch中填写点击取消按钮后执行的事件，例如消息提示“已取消删除”
          .catch(() => {
            this.$router.push({
              name: "MainView",
            })
          });

    },
    usrGetScore() {

      //1:单选 |2:多选|3:日期|4:单挑输入|5:多条输入
      for (let i = 0; i < this.form.data.dataList.length; i++) {
        // 单选逻辑判断
        if (this.form.data.dataList[i].topic.topic_answers_type == "1") {
          //再里边再增加几个判断 判断属于那个组的 把组号拿到 最后加分
          //1先拿到单条输入信息，把信息赋值给公司
          console.log("this.form.data.dataList[i]",this.form.data.dataList[i]);

          //如果用户没有选择保存0分
          if (this.form.data.dataList[i].answer.topic_single_selection.userSelection == "" ||
              this.form.data.dataList[i].answer.topic_single_selection.userSelection == null ||
              this.form.data.dataList[i].answer.topic_single_selection.userSelection == undefined
          ) {
            this.form.data.dataList[i].answer.get_score = 0;


          }else if(this.form.data.dataList[i].topic.topic_content == "所属分类"){
            this.form.data.dataList[i].answer.get_score = 0;
            // this.form.data.company.co_sort = this.form.data.dataList[i].answer.topic_single_selection
          //  拿到用户所选中的
            let userSelection= this.form.data.dataList[i].answer.topic_single_selection.userSelection;
            for (const item of this.form.data.dataList[i].answer.topic_single_selection.single_selection.options) {
              if (userSelection == item.value){
                this.form.data.company.co_sort =item.option
              }
            }

          }
          else {
            this.form.data.dataList[i].answer.get_score =
                this.form.data.dataList[i].answer.topic_single_selection.userSelection;
          }

        }

        // todo
        if (this.form.data.dataList[i].topic.topic_answers_type == "2") {

          this.form.data.dataList[i].answer.multiple_choice.multiple_choice.allvalue = 0;

          for (const item of this.form.data.dataList[i].answer.multiple_choice.multiple_choice.choices) {
            if (item.status == true) {
              this.form.data.dataList[i].answer.multiple_choice.multiple_choice.allvalue++;
            }
          }
          this.form.data.dataList[i].answer.get_score = this.form.data.dataList[i].answer.multiple_choice.multiple_choice.allvalue;
        }

        // todo
        if (this.form.data.dataList[i].topic.topic_answers_type == "3") {
          // console.log("3:日期" ,i+ this.form.data.dataList[i].topic.topic_content)
        }


        //单挑输入 over
        if (this.form.data.dataList[i].topic.topic_answers_type == "4") {
          // console.log("4:单挑输入" ,i+ this.form.data.dataList[i].topic.topic_content)

          if (this.form.data.dataList[i].topic.topic_content == "企业名称") {
            this.form.data.company.co_name =
                this.form.data.dataList[i].answer.single_entry
          } else if (this.form.data.dataList[i].topic.topic_content == "所属地区") {
            this.form.data.company.co_area =
                this.form.data.dataList[i].answer.single_entry
          }


          this.form.data.dataList[i].answer.get_score = 0;
        }


        // todo
        if (this.form.data.dataList[i].topic.topic_answers_type == "5") {
          if (this.form.data.dataList[i].topic.topic_content == "注册时间") {

            let RegistTime = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[0].values);
            let income = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[1].values);
            let year = Number(new Date().getFullYear());

            if (RegistTime == null || RegistTime == "" || income == "" || income == null) {
              this.form.data.dataList[i].answer.get_score = 0;
            } else if ((year - RegistTime) <= 5) {
              if (income >= 50) {
                this.form.data.dataList[i].answer.get_score = 8;
              } else if (income >= 30 && income < 50) {
                this.form.data.dataList[i].answer.get_score = 5;
              } else if (income < 30) {
                this.form.data.dataList[i].answer.get_score = 2;
              }

            } else if ((year - RegistTime) > 5) {

              if (income >= 500) {
                this.form.data.dataList[i].answer.get_score = 8;
              } else if (income >= 300 && income < 500) {
                this.form.data.dataList[i].answer.get_score = 5;
              } else if (income < 300) {
                this.form.data.dataList[i].answer.get_score = 3;
              }
            }

            // console.log("注册时间得分", this.form.data.dataList[i].answer.get_score);

          } else if (this.form.data.dataList[i].topic.topic_content == "营收成长性") {


            let info0 = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[0].values);
            let info1 = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[1].values);
            let info2 = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[2].values);
            // console.log("计算错误的值显示1", info0)
            // console.log("计算错误的值显示2", info1)
            // console.log("计算错误的值显示3", info2)
            if (info0 == null || info0 == "" || info1 == null || info1 == "" || info2 == null || info2 == "") {
              this.form.data.dataList[i].answer.get_score = 0;
              // console.log("info0 == null  被触发")
            } else if (info0 > info1 && info1 > info2 && info0 > info2) {
              this.form.data.dataList[i].answer.get_score = 1;
              // console.log("这个被触发  ===1")
            } else {
              this.form.data.dataList[i].answer.get_score = 0;
              // console.log("其他被触发")
            }


            // console.log("应收成长性得分得分", this.form.data.dataList[i].answer.get_score);


          } else if (this.form.data.dataList[i].topic.topic_content == "知识产权") {
            //循环判断 根据类型循环判断


            let increment = 0
            for (const item of this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item) {



              if (item.type == 1) {
                // console.log("item", item)
                if (item.values >= 1) {
                  this.form.data.dataList[i].answer.get_score = 5;
                  break;
                }
              } else if (item.type == 2) {
                if (item.values > 0) {
                  increment++;
                }


              } else if (item.type == 3) {
                this.form.data.dataList[i].answer.get_score = 0;

              } else {

                this.form.data.dataList[i].answer.get_score = 0;
              }




            }
            if (increment >= 2) {
              this.form.data.dataList[i].answer.get_score = 4;
            } else if (increment > 0 && increment < 2) {
              this.form.data.dataList[i].answer.get_score = 2;
            }



            // console.log("知识产权得分", this.form.data.dataList[i].answer.get_score);


          } else if (this.form.data.dataList[i].topic.topic_content == "研发费用") {
            let info0 = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[0].values);
            let info1 = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[1].values);

            // console.log("研发费用", info0);
            // console.log("总费用", info1);
            // console.log("(info0/info1)====>", (info0 / info1));

            if ((info0 / info1) >= 0.05) {
              this.form.data.dataList[i].answer.get_score = 5
              // console.log("(info0/info1)=", (info0 / info1));
            } else if ((info0 / info1) >= 0.03 && (info0 / info1) < 0.05) {
              this.form.data.dataList[i].answer.get_score = 4


            } else if ((info0 / info1) >= 0.02 && (info0 / info1) < 0.03) {
              this.form.data.dataList[i].answer.get_score = 3


            } else if ((info0 / info1) >= 0.01 && (info0 / info1) < 0.02) {
              this.form.data.dataList[i].answer.get_score = 2


            } else if ((info0 / info1) > 0.00 && (info0 / info1) < 0.01) {
              this.form.data.dataList[i].answer.get_score = 1

            } else {
              this.form.data.dataList[i].answer.get_score = 0

            }

            // console.log("研发费用", this.form.data.dataList[i].answer.get_score);

          } else if (this.form.data.dataList[i].topic.topic_content == "主要产品的行业分析") {
            let info0 = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[0].values);
            let info1 = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[1].values);

            if (info0 > 100000 && info1 >= 3) {
              this.form.data.dataList[i].answer.get_score = 4
            } else if (info0 > 100000 && info1 < 3 && info1 >= 0) {

              this.form.data.dataList[i].answer.get_score = 2
            } else if (info0 < 100000 && info1 >= 3) {
              this.form.data.dataList[i].answer.get_score = 2
            } else {
              this.form.data.dataList[i].answer.get_score = 0
            }

            // console.log("主要产品的行业分析", this.form.data.dataList[i].answer.get_score);


          } else if (this.form.data.dataList[i].topic.topic_content == "柏泉慧需求") {
            let amount = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[0].values);
            let item = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[1].values);

            let Total_score = 0;

            if (amount >= 10000) {
              Total_score += 2.5;
            } else if (amount > 0 && amount < 10000) {
              Total_score += 1.25;
            } else {
              Total_score += 0;
            }


            if (item >= 3) {
              Total_score += 2.5;

            } else if (item < 3 && item > 0) {
              Total_score += 1.25;
            } else {
              Total_score += 0;
            }


            this.form.data.dataList[i].answer.get_score = Total_score;
            // console.log("柏泉慧需求分数", typeof this.form.data.dataList[i].answer.get_score);


          } else if (this.form.data.dataList[i].topic.topic_content == "人力资源需求") {


            let amount = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[0].values);
            let item = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[1].values);

            let Total_score = 0;

            if (amount >= 5000) {
              Total_score += 1.5;
            } else if (amount > 0 && amount < 5000) {
              Total_score += 0.75;
            } else {
              Total_score += 0;
            }


            if (item >= 3) {
              Total_score += 1.5;

            } else if (item < 3 && item > 0) {
              Total_score += 0.75;
            } else {
              Total_score += 0;
            }


            this.form.data.dataList[i].answer.get_score = Total_score;
            // console.log("人力资源需求", this.form.data.dataList[i].answer.get_score);

          } else if (this.form.data.dataList[i].topic.topic_content == "片区需求") {
            let amount = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[0].values);
            let item = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[1].values);

            let Total_score = 0;

            if (amount >= 30000) {
              Total_score += 5;
            } else if (amount > 0 && amount < 30000) {
              Total_score += 2.5;
            } else {
              Total_score += 0;
            }


            if (item >= 2) {
              Total_score += 2;

            } else if (item < 2 && item > 0) {
              Total_score += 1;
            } else {
              Total_score += 0;
            }


            this.form.data.dataList[i].answer.get_score = Total_score;
            // console.log("片区需求", this.form.data.dataList[i].answer.get_score);

          } else if (this.form.data.dataList[i].topic.topic_content == "一竹需求") {

            let amount = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[0].values);
            let item = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[1].values);

            let Total_score = 0;

            if (amount >= 5000) {
              Total_score += 2.5;
            } else if (amount > 0 && amount < 5000) {
              Total_score += 1.25;
            } else {
              Total_score += 0;
            }


            if (item >= 3) {
              Total_score += 2.5;

            } else if (item < 3 && item > 0) {
              Total_score += 1.25;
            } else {
              Total_score += 0;
            }


            this.form.data.dataList[i].answer.get_score = Total_score;
            // console.log("一竹需求", this.form.data.dataList[i].answer.get_score);

          } else if (this.form.data.dataList[i].topic.topic_content == "顺丽需求") {


            let amount = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[0].values);
            let item = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[1].values);

            let Total_score = 0;

            if (amount >= 5000) {
              Total_score += 2.5;
            } else if (amount > 0 && amount < 5000) {
              Total_score += 1.25;
            } else {
              Total_score += 0;
            }


            if (item >= 3) {
              Total_score += 2.5;

            } else if (item < 3 && item > 0) {
              Total_score += 1.25;
            } else {
              Total_score += 0;
            }


            this.form.data.dataList[i].answer.get_score = Total_score;
            // console.log("顺丽需求", this.form.data.dataList[i].answer.get_score);

          } else if (this.form.data.dataList[i].topic.topic_content == "汇雅需求") {
            let amount = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[0].values);
            let item = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[1].values);

            let Total_score = 0;

            if (amount >= 10000) {
              Total_score += 2.5;
            } else if (amount > 0 && amount < 5000) {
              Total_score += 1.25;
            } else {
              Total_score += 0;
            }


            if (item >= 2) {
              Total_score += 2.5;

            } else if (item < 2 && item > 0) {
              Total_score += 1.25;
            } else {
              Total_score += 0;
            }


            this.form.data.dataList[i].answer.get_score = Total_score;
            // console.log("汇雅需求", this.form.data.dataList[i].answer.get_score);
          } else if (this.form.data.dataList[i].topic.topic_content == "品宣需求") {
            let item = Number(this.form.data.dataList[i].answer.multiple_inputs.inputvalues.item[0].values);
            let Total_score = 0;
            if (item >= 2) {
              Total_score += 5;

            } else if (item < 2 && item > 0) {
              Total_score += 2.5;
            } else {
              Total_score += 0;
            }

            this.form.data.dataList[i].answer.get_score = Total_score;
          }


        }

        console.log("每道题得分=》" + i, this.form.data.dataList[i].answer.get_score)

      }

      console.log("this.form.data.dataList", this.form.data)
    },
    getGroup() {
      this.usrGetScore();
      let Policy_scores_index = this.form.data.groups[0].group_index;
      let Industry_scores = this.form.data.groups[1].group_index;
      let Business_scores = this.form.data.groups[2].group_index;

      this.form.data.groups[0].group_score = 0;
      this.form.data.groups[1].group_score = 0;
      this.form.data.groups[2].group_score = 0;
      this.form.data.groups[0].group_score = Number(this.form.data.groups[0].group_score)
      this.form.data.groups[1].group_score = Number(this.form.data.groups[1].group_score)
      this.form.data.groups[2].group_score = Number(this.form.data.groups[2].group_score)


      for (let i = 0; i < this.form.data.dataList.length; i++) {
        if (Policy_scores_index == this.form.data.dataList[i].topic.group_index) {
          // console.log(this.form.data.dataList[i]);
          // console.log("得分", parseInt(this.form.data.dataList[i].answer.get_score))
          this.form.data.groups[0].group_score += Number(this.form.data.dataList[i].answer.get_score);
        } else if (Industry_scores == this.form.data.dataList[i].topic.group_index) {
          this.form.data.groups[1].group_score += Number(this.form.data.dataList[i].answer.get_score);
        } else if (Business_scores == this.form.data.dataList[i].topic.group_index) {
          this.form.data.groups[2].group_score += Number(this.form.data.dataList[i].answer.get_score);
        }
      }

      // this.form.data.company.policy_scores =this.form.data.company.policy_scores;
      this.form.data.company.policy_scores = this.form.data.groups[0].group_score;
      this.form.data.company.industry_scores = this.form.data.groups[1].group_score;
      this.form.data.company.business_scores = this.form.data.groups[2].group_score;

      this.form.data.company.gross_scorce += this.form.data.company.policy_scores;
      this.form.data.company.gross_scorce += this.form.data.company.industry_scores;
      this.form.data.company.gross_scorce += this.form.data.company.business_scores;

      // console.log("gross_scorce", this.form.data.company.gross_scorce);

      this.form.data.questionnaire.gross_score = this.form.data.company.gross_scorce;
      this.form = JSON.parse(JSON.stringify(this.form.data));
      // console.log("全部更新数据，拿到数据处理")
    },


  },
  created() {

    this.fullscreenLoading = true

    if (this.form.data.groups != null || this.form.data.groups != undefined) {
      // this.fullscreenLoading= false
    }

    request.get("http://106.15.57.195:10000/getBean").then((result) => {


      this.form = JSON.parse(JSON.stringify(result));


      //判断之后json转换
      //把后台数据转换json再保存节点上
      for (let i = 0; i < this.form.data.dataList.length; i++) {
        //1:单选 |2:多选|3:日期|4:单挑输入|5:多条输入
        if (this.form.data.dataList[i].topic.topic_answers_type == "1") {
          this.form.data.dataList[i].answer.topic_single_selection =
              JSON.parse(this.form.data.dataList[i].answer.topic_single_selection)
        }


        if (this.form.data.dataList[i].topic.topic_answers_type == "2") {
          this.form.data.dataList[i].answer.multiple_choice =
              JSON.parse(this.form.data.dataList[i].answer.multiple_choice)

          // this.form.data.dataList[i].answer.multiple_choice =
          //     JSON.parse(this.form.data.dataList[i].answer.multiple_choice)
          // console.log("info=2", i)
        }


        // if (this.form.data.dataList[i].topic.topic_answers_type == "3") {
        //
        // }
        if (this.form.data.dataList[i].topic.topic_answers_type == "4") {
          this.form.data.dataList[i].answer.single_entry =
              JSON.parse(this.form.data.dataList[i].answer.single_entry);
        }

        if (this.form.data.dataList[i].topic.topic_answers_type == "5") {
          this.form.data.dataList[i].answer.multiple_inputs =
              JSON.parse(this.form.data.dataList[i].answer.multiple_inputs)

        }

      }


      this.fullscreenLoading = false
    })
    //根据窗口宽度来给定css
    this.windowWidthInfo = document.documentElement.clientWidth < 500 ? "el-optionByPhone" : "el-optionByWin";

  },


}
</script>

<style scoped>


.example-showcase .el-loading-mask {
  z-index: 9;
}


#root {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}


#header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;
  background-color: #549fc9;
  position: fixed;
  z-index: 100;
}

#info {
  width: 80%;
  /*height: 30px;*/
  /*background-color: sandybrown;*/
  /*display: flex;*/
  /*flex-wrap: wrap;*/
}


.content {
  -webkit-box-flex: 1;
  overflow: auto;
  width: 96%;
  margin-top: 70px;
  margin-left: 2%;
  font-weight: bolder;
}

.el-affix el-alert {
  display: inline-block;
  width: 20%;
}


#topics {

}

#topics .el-form {
  display: flex;
  flex-direction: column;
}

.el-select {
  flex: 1;


}

.top-button {
  margin-right: 10px;
}


.el-optionbyWin {
  display: inline-block;
  max-width: 100%;
  display: table;
  white-space: pre-wrap;
}

.el-optionByPhone {
  display: inline-block;
  max-width: 100%;
  display: table;
  white-space: pre-wrap;
}


.el-checkbox {
  /*需要查一下*/
  white-space: normal;
  word-break: break-all;
  margin-top: 1%;
}

#submit {
  display: flex;
  justify-content: center;
}


/*判断是否有标识*/
.flag_yellow {
  border-bottom: 2px solid #ffc000;
}


.flag_blue {
  border-bottom: 2px solid darkblue;

}


.dialog-footer button:first-child {
  margin-right: 10px;
}


#PopInfo {
  /*height: 300px;*/
}

#PopInfo h2 {
  font-size: 15px;
  margin-top: 20px;
}

.span-yellow {
  color: #ffc000;

}

.span-blue {
  color: darkblue;

}

.text-large {
  color: white;
}

.el-page-header {
  color: white;

}
</style>