<template>
  <div id="box">
    <div class="context">


      <el-card class="box-card">
        <h1>企业综合测评网站</h1>
        <el-form
            ref="user"
            :model="user"
            :rules="rules"
            status-icon
        >
          <el-form-item prop="name" label="账号">
            <el-input v-model="user.name"  />
          </el-form-item>

          <el-form-item  prop="password" label="密码">
            <el-input  type="password" v-model="user.password" />
          </el-form-item>


          <el-form-item class="sub">
            <el-button @click="clear()">重置</el-button>
            <el-button type="primary" @click="login()">登录</el-button>
          </el-form-item>

        </el-form>



      </el-card>
    </div>
  </div>
</template>

<script>
import request from "@/network/request";
export default {
  name: "LogIn",
  data(){
    return{
      user:{},
      rules:{
        name: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
      }
    }
  },methods:{
    clear(){
      this.user ={};
    },
    login(){
      this.user =JSON.parse(JSON.stringify(this.user));
      this.$refs['user'].validate((valid) =>{
        // console.log("valid",valid)
        if (valid){
          request.post("http://106.15.57.195:10000/login",this.user).then((res)=>{
            if (res.data.code == "200"){

              this.$message({
                //弹出更新成功的消息框
                type: "success",
                message: "登录成功",
              })

              localStorage.setItem("token",JSON.stringify(res.data.data))
              //路由跳转 可以直接使用这个
              this.$router.replace({path:"/"})
            }else {
              this.$message({
                //弹出更新成功的消息框
                type: "error",
                message: "账号密码错误",
              })

            }
          })

        }

      })

    }
  }
}
</script>

<style scoped>
#box {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-image: url("../../assets/img/1.jpg");
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
  background-size    : cover;
  background-position: top;

}

.context {
  width: 375px;
  height: 400px;
  /*background-color: white;*/
  /*display: flex;*/
  /*flex-direction: column;*/
}
.box-card {

}
.box-card h1{
  margin-top: 5%;
  margin-bottom: 10%;
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
  /*margin: 0 auto;*/
}
.sub{
  margin-left: 25%;
  /*margin: 0 auto;*/
  /*background-color: #409eff;*/
}

</style>