import axios from "axios";


//超时时间5秒
const request = axios.create({ timeout: 10000 })

// 求数据
request.interceptors.request.use(config => {
    console.log(config)
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    return config
}, error => { return Promise.reject(error) });

// 返回数据
// request.interceptors.response.use(response => {
//     let res = response.data;
//
//     if (response.config.responseType === 'blob') { return res }
//
//     if (typeof res === 'string') { res = res ? JSON.parse(res) : res }
//
//     return res;
// }, error => {
//
// })

export default request;