import {createRouter, createWebHistory} from 'vue-router'
import MainView from "@/views/version2/MainView";
import Topic from "@/views/version2/Topic";
import ViewTopic from "@/views/version2/ViewTopic";
import ViewResult from "@/views/version2/ViewResult";
import LogIn from "@/views/version2/LogIn";
import UpdateTopic from "@/views/version2/UpdateTopic";

import request from "@/network/request";
import { ElMessage } from 'element-plus'
const routes = [
    {
        //主视图
        path: '/',
        name: 'MainView',
        component: MainView
    },
    {
        //登录
        path: '/login',
        name: 'LogIn',
        component: LogIn
    },
    {
        //新建题目
        path: '/topic',
        name: 'Topic',
        component: Topic
    },
    {   //查看试题
        path: '/viewtopic/:index',
        name: 'ViewTopic',
        component: ViewTopic
    },
    {   //提交结果视图
        path: '/viewresult/:id',
        name: 'ViewResult',
        component: ViewResult
    },

    {   //跟新题目视图
        path: '/updatetopic/:index',
        name: 'UpdateTopic',
        component: UpdateTopic
    }



]

const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes
})


//获取token
router.beforeEach((to,from,next) => {
    let userToken = localStorage.getItem('token');
    if(to.path === '/login'){ //若是进入登录与注册页面 ==> pass
        next()
    }else{
        // let userToken = localStorage.getItem('token');
        // console.log("Token为:"+userToken);
        if(userToken == null || userToken == ''){
            ElMessage({
                message: '请先登录.',
                type: 'warning',
            })
            return next('/login');
        }else{
           let tokenObj =JSON.parse(userToken);


           let  tokenStr= JSON.stringify(tokenObj.token);
            request.post("http://106.15.57.195:10000/checkToken",tokenStr).then(res=>{

                // console.log("验证是否成功",res)
                if (res.data){
                    next();
                }else {
                    alert("请您先登录")
                    next('/login');

                }

            })
        }
    }
})

export default router
