<template>
  <div id="root">
    <div id="header">
      <div>
        <el-page-header @back="handleToPageMainView()"  class="el-page-header" :icon="ArrowLeft">
          <template #content>
            <span class="text-large font-600 mr-3"> 查看企业信息 </span>
          </template>
          <template #extra>
            <div class="flex items-center">
              <el-button class="top-button"  @click="dialogVisible = true">分数总览</el-button>
              <!--            <el-button class="ml-2" :icon="Edit" circle />-->
            </div>
          </template>
        </el-page-header>
      </div>
      <!--      存放信息-->

    </div>

    <div class="content">
      <div id="topics">
        <div>
          <!--表单设置-->
          <el-form
              ref="form"
              :model="this.form"
              label-width="top"
              :label-position="labelPosition"
              :size="size"
          >

            <el-form-item v-for="(items,index) in this.form.data.dataList"
                          :class="{'flag_blue': Number(items.topic.topic_flag,) === 2 ,
                        'flag_yellow': Number(items.topic.topic_flag,) === 1}"
                          :label="items.topic.topic_content">
              <!--          el-select  使用说明
                            v-model= 该值绑定选中选项
              -->


              <!--单选 1  -->
              <el-select
                  disabled
                  v-if="Number(items.topic.topic_answers_type) == 1"
                  class="el-select"
                  v-model="items.answer.topic_single_selection.userSelection"
                  :placeholder="items.topic.topic_placeholder">
                <!-- v-for是值 -->
                <el-option class="el-option"
                           v-for=" (opt,index) in
                          items.answer.topic_single_selection.single_selection.options"

                           :label="opt.option"
                           :value="opt.value"/>
              </el-select>

              <!--              {{ Number(items.topic.topic_answers_type) == 2?items.answer.multiple_choice.multiple_choice.choices :false}}-->

              <!--多选 2-->
              <el-checkbox class="el-checkbox" v-if="Number(items.topic.topic_answers_type) == 2"
                           v-for="(choices,index) in  items.answer.multiple_choice.multiple_choice.choices"
                           v-model="choices.status"
                           disabled
                           :label="choices.option"/>


              <!--单条输入 4  -->
              <el-input
                  disabled
                  :placeholder="Number(items.topic.topic_answers_type) == 4?items.topic.topic_placeholder:false"
                  v-if="Number(items.topic.topic_answers_type) == 4"
                  v-model="items.answer.single_entry"/>

              <!--多条输入-->
              <el-input
                  disabled
                  v-for="(i,index) in  items.answer.multiple_inputs.inputvalues.item"
                  :placeholder="Number(items.topic.topic_answers_type) == 5?i.name:false"
                  v-if="Number(items.topic.topic_answers_type) == 5"
                  v-model="i.values">
                <template #prepend>{{ i.name }}</template>
                <template #append>{{ i.unit }}</template>
              </el-input>


            </el-form-item>

          </el-form>
        </div>

        <!--      <div id="submit">-->
        <!--        <el-button @click="">取消</el-button>-->
        <!--        <el-button type="primary" @click="submitForm">提交</el-button>-->
        <!--      </div>-->

      </div>
    </div>

    <div>
      <div id="submit">
        <el-button @click="handleToPageMainView()">返回</el-button>

        <el-button
            type="primary" @click="handleUpadate()">
          编辑
        </el-button>
    </div>
    </div>

    <div>
      <el-dialog
          v-model="dialogVisible"
          title="总分预览"
          width="80%"
          :before-close="handleClose"
      >
        <div id="PopInfo">
          <div><span>企业名称:</span>{{this.form.data.company.co_name }}</div>
          <div><span>企业地区:</span>{{this.form.data.company.co_area }}</div>
          <div><span>企业分类:</span>{{this.form.data.company.co_sort }}<span></span></div>
          <div><span>企业得分:</span>{{this.form.data.company.gross_scorce}}</div>
          <div><span>企业等级:</span>{{getLevel}}<span></span></div>
          <div><span>国家政策:</span>{{this.form.data.company.policy_scores}}</div>
          <div><span>产业导向:</span>{{this.form.data.company.industry_scores}}</div>
          <div><span>衍生业务:</span>{{this.form.data.company.business_scores}}</div>

        </div>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">我知道了</el-button>
      </span>
        </template>
      </el-dialog>
    </div>


  </div>
</template>

<script>
import request from "@/network/request";

export default {


  name: "ViewTopic",
  data() {
    return {
      form: {},
      sendForm: {},
      size: "large", //表单字体 表单控件
      labelPosition: "top", //表单方向 表单控件
      status: 1 ,//状态信息 可以删除测试之后
      fullscreenLoading:false, //loading加载参数
      dialogVisible:false,
      level:""
    };
  },
  methods: {
    openFullScreen1(){
      this.fullscreenLoading= true
      setTimeout(() => {
        this.fullscreenLoading= false
      }, 1000)
    },
    submitForm() {
      this.openFullScreen1();
      this.form = JSON.parse(JSON.stringify(this.form.data));
      console.log(JSON.stringify(this.form))

      // console.log("this.form.data.dataList[0]"+this.form.data.dataList[0])
      request.post("http://106.15.57.195:10000/testA", this.form).then((result) => {
        console.log("当前请求路径=》http://106.15.57.195:10000/testA");

      })
    },
    handleToPageMainView() {
      this.$router.push({
        name: "MainView"
      })
    },

    handleUpadate(){
      // 处理更新
      let uriInfo=this.$route.path.substring(11)
      this.$message({
        //弹出更新成功的消息框
        type: "success",
        message: "准备更改，请稍等",
      })
      this.$router.push({
        name:"UpdateTopic",
        params:{
          index:uriInfo
        }

      })
    }

  },
  created() {
    console.log("(this.$route.path).substring(13)).then((result)====>",
        ((this.$route.path).substring(11)))
    //((this.$route.path).substring(13))
    request.get("http://106.15.57.195:10000/queryCo/" + (this.$route.path).substring(11)).then((result) => {
      console.log("result=>信息", result);

      this.form = JSON.parse(JSON.stringify(result));

      //判断之后json转换
      //把后台数据转换json再保存节点上
      for (let i = 0; i < this.form.data.dataList.length; i++) {
        //1:单选 |2:多选|3:日期|4:单挑输入|5:多条输入
        if (this.form.data.dataList[i].topic.topic_answers_type == "1") {


          this.form.data.dataList[i].answer.topic_single_selection =
              JSON.parse(this.form.data.dataList[i].answer.topic_single_selection)

          this.form.data.dataList[i].answer.topic_single_selection =
              JSON.parse(this.form.data.dataList[i].answer.topic_single_selection);
        }

        // console.log(i)


        //
        if (this.form.data.dataList[i].topic.topic_answers_type == "2") {

          this.form.data.dataList[i].answer.multiple_choice =
              JSON.parse(this.form.data.dataList[i].answer.multiple_choice)

          this.form.data.dataList[i].answer.multiple_choice =
              JSON.parse(this.form.data.dataList[i].answer.multiple_choice)

        }


        // if (this.form.data.dataList[i].topic.topic_answers_type == "3") {
        //
        // }
        //
        if (this.form.data.dataList[i].topic.topic_answers_type == "4") {
          // console.log("single_entry==>",i)
          console.log("single_entry==>",this.form.data.dataList[i].answer.single_entry)
          this.form.data.dataList[i].answer.single_entry=
              JSON.parse(this.form.data.dataList[i].answer.single_entry);

        }

        // 5:多条输入
        if (this.form.data.dataList[i].topic.topic_answers_type == "5") {


          this.form.data.dataList[i].answer.multiple_inputs =
              JSON.parse(this.form.data.dataList[i].answer.multiple_inputs)

          this.form.data.dataList[i].answer.multiple_inputs =
              JSON.parse(this.form.data.dataList[i].answer.multiple_inputs)
        }
        console.log("i", i)

      }


      console.log("form", this.form)


    })
  },
  computed:{

    getLevel(){
      if (Number(this.form.data.company.gross_scorce)>=55){
        return  "A"
      }else if (Number(this.form.data.company.gross_scorce)>=50){

        return  "B"
      }else if (Number(this.form.data.company.gross_scorce)>=45){

        return  "C"
      }else if (Number(this.form.data.company.gross_scorce)>=40){
        return  "C"
      }else {
        return "其他"
      }
    }


  }

}
</script>

<style scoped>
#root {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

#header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;
  background-color:#549fc9;
  position: fixed;
  z-index: 100;
}





.content {
  -webkit-box-flex: 1;
  overflow: auto;
  width: 96%;
  margin-top: 70px;
  margin-left: 2%;
  font-weight: bolder;

}

.el-affix el-alert {
  display: inline-block;
  width: 20%;
}


#topics {

}

#topics .el-form {
  display: flex;
  flex-direction: column;
}

.el-select {
  flex: 1;


}

.el-option {
  display: inline-block;
  max-width: 100%;
  display: table;
  white-space: pre-wrap;
}

.el-checkbox {
  /*需要查一下*/
  white-space: normal;
  word-break: break-all;
  margin-top: 1%;
}

#submit {
  display: flex;
  justify-content: center;
}


/*判断是否有标识*/
.flag_yellow {
  border-bottom: 2px solid #ffc000;
}


.flag_blue {
  border-bottom: 2px solid darkblue;

}

.top-button{
  margin-right: 10px;
}

.el-page-header{
  color: white;

}
.text-large{
  color: white;
}

#PopInfo div{

   margin-top: 20px;
}

#PopInfo span{
  margin-left: 30px;
  margin-right: 10px;
  font-weight: bolder;
}
</style>